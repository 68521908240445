import React, { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

// custom debounce fn
const useDebounce = (fn, delay) => {
  let timeout;

  // return function that takes arg and apply to the function
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

const Search = ({ setSearch, setCurrentPage, placeholder, bgColor, textColor }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearch = useDebounce((value) => {
    setCurrentPage(1);
    setSearch(value);
  }, 1500); // Adjust the delay as needed (in milliseconds)

  const handleSearch = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchTerm(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  return (
    <div className="w-[200px] relative ">
      <FontAwesomeIcon
        icon={faSearch}
        className="absolute text-sm"
        style={{ left: "8px", top: "9px" }}
      />
      <input
        type="search"
        placeholder={placeholder || "Search..."}
        className={`border ${textColor} ${bgColor || 'bg-slate-100 focus:bg-transparent'} border-slate-300 text-sm w-full focus:border-customGreen rounded-md duration-200 pl-7 focus:ring-0 outline-none focus:pl-8  `}
        value={searchTerm}
        onChange={handleSearch}
      />
    </div>
  );
};

export default Search;
