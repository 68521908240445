import React, { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import background from "../images/cardbase.png";
import logo from "../images/TestAssessify.png";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { UserContext } from "../context/UserContext";
import { api } from "../api";
import { hashSync } from "bcryptjs";
import ForgotPasswordModal from "./ResetPassword/ForgotPasswordModal";
import CryptoJS from "crypto-js";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCustomToast } from "../components/customtoast/CustomToastProvider";
import { ClipLoader } from "react-spinners";

export function SigninPage() {
  const { register, handleSubmit } = useForm();
  const navigation = useNavigate();
  const { mutateAsync: onLogin } = useMutation(api.login);
  const { value, setValue } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotModal, hideForgotModal] = useState(false);
  const [loginAsAdmin, setLoginAsAdmin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const key = process.env.REACT_APP_SECRET_KEY;

  const customToast = useCustomToast();

  const styles = {
    container: {
      width: "35%",
      margin: "0 auto",
      padding: "2%",
      border: "2px grey solid",
    },
    input: {
      width: "100%",
      border: "1px grey solid",
      padding: "1%",
    },
  };

  function greetTime() {
    const today = new Date();
    const hourOfDay = today.getHours(); //The current hour of today
    if (hourOfDay > 11 && hourOfDay <= 16) {
      return "afternoon";
    } else if (hourOfDay > 16) {
      return "evening";
    } else {
      return "morning";
    }
  }

  const onSubmit = async (payload) => {
    setIsLoading(true);
    // toast.loading("Logging in...");
    try {
      const data = await onLogin({
        ...payload,
        account_type: loginAsAdmin ? "admin" : "ambassador",
      });
      // console.log(data);
      setValue((prev) => {
        return { ...prev, user: data?.data?.user || null };
      });
      const encryptedToken = CryptoJS.AES.encrypt(
        data?.data?.token,
        key
      ).toString();
      sessionStorage.setItem("isLoggedIn", true);
      sessionStorage.setItem("userToken", encryptedToken);
      sessionStorage.setItem("user", JSON.stringify(data?.data));
      sessionStorage.setItem(
        "admin-role",
        hashSync(data?.data?.designation_slug, 10)
      );

      setTimeout(() => {
        toast.remove();
        customToast.success("Logged in successfully...");
        setIsLoading(false);
        if (loginAsAdmin) navigation("/admin");
        else navigation("/affiliate/");
      }, 1000);
    } catch ({ response }) {
      setIsLoading(false);
      toast.remove();
      customToast.error(response?.data?.message);
    }
  };

  const handleReset = () => {
    hideForgotModal(true);
  };

  const handleSignup = () => {
    navigation("/register");
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const toggleAccountType = () => {
    setLoginAsAdmin(!loginAsAdmin);
  };

  return (
    <React.Fragment>
      {showForgotModal && <ForgotPasswordModal hideModal={hideForgotModal} />}
      <div
        className="flex flex-col justify-center w-full"
        style={{
          backgroundColor: "whitesmoke",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        {/*<AdminHeader />*/}
        <div className="w-full p-3 pt-0 h-14 bg-inherit sticky top-0">
          <div className="h-full">
            <img
              src={logo}
              alt="Test Assessify"
              className="w-[200px] h-[60px] object-contain"
            />
          </div>
        </div>
        <div
          className="flex flex-col justify-center md:flex-row md:justify-end items-center p-4 w-3/4 mx-auto shadow-md"
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "90vh",
          }}
        >
          <div className="w-full md:w-1/3 md:mr-10">
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex md:items-center justify-start w-full  overflow-hidden">
                <h1
                  className="text-2xl md:w-3/4 object-contain font-medium"
                  style={{ color: "oliveDrab" }}
                >
                  Admin Panel Sign in
                </h1>
              </div>
              <p className="my-4 text-baseFont text-slate-500">
                Good {greetTime()},
                <br /> Please sign into your account
              </p>
              <input
                className="block text-baseFont w-full border p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen"
                type="email"
                {...register("email", {
                  required: true,
                })}
                placeholder="Enter your email"
              />
              <div className="mb-4 relative">
                <input
                  className="block w-full text-baseFont border p-2 rounded-md outline-none focus:ring-0 focus:border-armyGreen"
                  type={showPassword ? "text" : "password"}
                  required
                  {...register("password", {
                    required: true,
                  })}
                  placeholder="Enter your password"
                />
                <span
                  className="absolute right-2 text-slate-400 cursor-pointer"
                  style={{ top: "8px" }}
                  onClick={(e) => handleShowPassword(e)}
                >
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </span>
              </div>
              <span className="text-slate-700 text-baseFont">
                <input type="checkbox" />
                &nbsp; Keep me signed in
              </span>
              <span className="text-slate-700 ml-8 text-baseFont">
                <input
                  onChange={toggleAccountType}
                  type="checkbox"
                  checked={!loginAsAdmin}
                />
                &nbsp; Login As Affiliate
              </span>
              <br />
              <button
                type="submit"
                id="submit"
                disabled={isLoading ? true : false}
                className={` ${
                  isLoading ? "cursor-not-allowed opacity-70" : "cursor-pointer"
                } text-baseFont w-full mt-4 mx-auto rounded-md flex items-center duration-200 justify-center bg-customBlue hover:text-customGreen text-white h-10`}
              >
                {isLoading ? (
                  <ClipLoader size={15} color={"#fff"} />
                ) : (
                  "Sign in"
                )}
              </button>
            </form>
            <div className="w-full flex items-center justify-start flex-col gap-7 mt-5">
              <span
                className="w-full"
                style={{ fontSize: "12px", fontWeight: "500" }}
              >
                Forgot Password?{" "}
                <button
                  onClick={handleReset}
                  className="hover:underline text-baseFont text-customGreen"
                >
                  Click to reset
                </button>
              </span>
              <span
                className="w-full mt-0"
                style={{ fontSize: "12px", fontWeight: "500" }}
              >
                New to TestAssessify?{" "}
                <button
                  onClick={handleSignup}
                  className="hunderline text-baseFont text-customGreen"
                >
                  Sign Up
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SigninPage;
