import React from "react";
import { ScaleLoader } from "react-spinners";

const Loader = () => {
  return (
    <div
      className="w-full flex items-center justify-center gap-2"
      style={{ height: "300px" }}
    >
      <ScaleLoader size={150}  color={"#87aa4b"} />
    </div>
  );
};

export default Loader;
