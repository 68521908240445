import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

const perPageOptions = [5, 8, 10, 15, 25, 35, 45];

const Pagination = ({ pageNo, setPageNo, data, perPage, setPerPage }) => {
  const [currentPageNo, setCurrentPageNo] = useState(0);
  // console.log({ data });
  const length = data?.last_page || null;

  const pages = Array.from({ length }, (_, index) => index + 1);
  const chunkPages = [];

  //if size of pages is more than 10, then break pages it sub-arrays of 10 items
  if (pages.length > 10) {
    const pageSize = 10;
    for (let i = 0; i < pages.length; i += pageSize) {
      const chunk = pages.slice(i, i + pageSize);
      chunkPages.push(chunk);
    }
  } else {
    chunkPages.splice(0, chunkPages.length);
  }

  //   console.log({ chunkPages });

  // console.log(chunkPages.length);

  //   console.log({ pages });
  const handlePerPage = (e) => {
    setPageNo(1);
    setPerPage(e.target.value);
  };

  if (!data || data?.last_page === 1) {
    return (
      <div className="mt-6 mb-2 w-full flex items-center gap-4 justify-end">
        {perPage && (
          <select
            value={perPage}
            className="text-sm border border-slate-300 py-2 text-slate-500 rounded-md focus:ring-0 duration-200 outline-none focus:border-customBlue"
            onChange={handlePerPage}
          >
            {perPageOptions.map((perPageOption) => (
              <option key={perPageOption} value={perPageOption}>
                {perPageOption} per page
              </option>
            ))}
          </select>
        )}
      </div>
    );
  }

  //   console.log({ data });

  return (
    <div className="mt-6 mb-2 w-full flex items-center gap-4 justify-between">
      <div className="flex items-center justify-center gap-4">
        {/*BACK BUTTON */}
        <button
          className={`text-xl flex items-center justify-center h-10 ${
            chunkPages.length > 0 && currentPageNo !== 0
              ? "text-customBlue"
              : "text-slate-300"
          }`}
          disabled={chunkPages.length > 0 && currentPageNo !== 0 ? false : true}
          onClick={() => {
            setCurrentPageNo(currentPageNo - 1);
            setPageNo(chunkPages[currentPageNo - 1][0]);
          }}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>

        {/*PAGINATION BUTTONS */}
        {chunkPages.length < 1 &&
          pages?.map((page) => (
            <button
              className={
                page === data?.current_page
                  ? "text-white py-1 px-2 bg-primary"
                  : "text-customBlue"
              }
              key={page}
              onClick={() => {
                setPageNo(page);
              }}
            >
              {page}
            </button>
          ))}
        {chunkPages.length > 0 &&
          chunkPages[currentPageNo]?.map((page) => (
            <button
              className={
                page === data?.current_page
                  ? "text-customOrange"
                  : "text-customBlue"
              }
              key={page}
              onClick={() => {
                setPageNo(page);
              }}
            >
              {page}
            </button>
          ))}
        {chunkPages.length > 0 && chunkPages[currentPageNo] && (
          <div className="flex items-center justify-center gap-1">
            <p>...</p>
            <button
              className={
                pageNo === data?.last_page
                  ? "text-customOrange"
                  : "text-customBlue"
              }
              onClick={() => {
                setPageNo(data?.last_page);
              }}
            >
              {data?.last_page}
            </button>
          </div>
        )}
        {/*FORWARD BUTTON */}
        <button
          className={`text-xl flex items-center justify-center h-10 ${
            chunkPages.length > 0 && currentPageNo !== chunkPages.length - 1
              ? "text-customBlue"
              : "text-slate-300"
          }`}
          disabled={
            chunkPages.length > 0 && currentPageNo !== chunkPages.length - 1
              ? false
              : true
          }
          onClick={() => {
            setCurrentPageNo(currentPageNo + 1);
            setPageNo(chunkPages[currentPageNo + 1][0]);
          }}
        >
          <FontAwesomeIcon icon={faCaretRight} />
        </button>
      </div>
      {perPage && (
        <select
          value={perPage}
          className="text-sm border border-slate-300 text-slate-500 rounded-md py-2 focus:ring-0 duration-200 outline-none focus:border-customBlue"
          onChange={handlePerPage}
        >
          {perPageOptions.map((perPageOption) => (
            <option key={perPageOption} value={perPageOption}>
              {perPageOption} per page
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default Pagination;
